import { Tooltip, Toast, Popover } from 'bootstrap5'
import PerfectScrollbar from 'perfect-scrollbar'
import ImageCompare from "image-compare-viewer";
import Swiper, { Navigation, Autoplay } from 'swiper';
Swiper.use([Navigation, Autoplay]);

import 'swiper/css/bundle'


// import { Chart, DoughnutController } from 'chart.js';
import Chart from 'chart.js/auto';

// import 'waypoints/lib/noframework.waypoints.min.js';


try {
  window.$ = window.jQuery = require('jquery/dist/jquery');
  require('@fancyapps/fancybox');
} catch (e) { }

/* eslint-disable no-undef */
document.addEventListener('DOMContentLoaded', function (event) {
  console.log('FE LOADING')

  /****************************************************************
   * GLOBAL
   ****************************************************************/

  // MOBILE MENU
  setTimeout(function () {
    // console.log('SET ACTIVE :D')
      setupMobileMenu()
  }, 700)


  // SETUP MODULES
  ltxChartDoughnut()

  /****************************************************************
   * HOME PAGE
   ****************************************************************/

  // eslint-disable-next-line no-unused-vars
  const swiper = new Swiper('.swiper-container', {
    direction: 'horizontal',
    loop: true,
    effect: 'fade',
    fadeEffect: {
      crossFade: true
    },
    slidesPerView: 1,
    autoplay: {
      delay: 5000
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true
    }
  })

  // TESTIMONIAL Home SWIPER
  new Swiper('.posts-swiper-container', {
    // direction: 'horizontal',
    loop: false,
    autoplay: {
      delay: 5000
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    slidesPerView: 3,
    spaceBetween: 10,
    watchOverflow: true,
    breakpoints: {
      320: {
        slidesPerView: 1.5,
        spaceBetween: 10
      },
      767: {
        slidesPerView: 2.4,
        spaceBetween: 15
      },
      992: {
        slidesPerView: 3,
        spaceBetween: 20
      },
      1600: {
        slidesPerView: 4,
        spaceBetween: 20
      }
    }
  })

  new Swiper('#testimonial-world .testimonial-swiper-container', {
    direction: 'horizontal',
    loop: true,
    autoplay: {
      delay: 5000
    },
    navigation: {
      nextEl: '.button-next',
      prevEl: '.button-prev',
    },
    slidesPerView: 3,
    spaceBetween: 10,
    watchOverflow: true,
    breakpoints: {
      320: {
        slidesPerView: 1,
        spaceBetween: 20
      },
      992: {
        slidesPerView: 2,
        spaceBetween: 40
      },
      1600: {
        slidesPerView: 3,
        spaceBetween: 40
      }
    }
  })

  // TESTIMONIAL FULL SWIPER
  new Swiper('#testimonial-full .testimonial-swiper-container', {
    direction: 'horizontal',
    loop: true,
    autoplay: {
      delay: 5000
    },
    navigation: {
      nextEl: '.button-next',
      prevEl: '.button-prev',
    },
    slidesPerView: 3,
    spaceBetween: 10,
    watchOverflow: true,
    breakpoints: {
      320: {
        slidesPerView: 1,
        spaceBetween: 20
      },
      992: {
        slidesPerView: 2,
        spaceBetween: 40
      },
      1600: {
        slidesPerView: 3,
        spaceBetween: 40
      }
    }
  })


  /**
   * HOME SERVICE COMPONENT
   **/
    const element = document.getElementById('services-component')
    if (typeof element !== 'undefined' && element != null) {
      // Scroll Bar
      const container = document.querySelector('#services-component .list')
      // eslint-disable-next-line no-unused-vars
      const ps = new PerfectScrollbar(container)

      // SERVICE COMPONENT TAB SWITCHER
      $('#services-component .list .list-item').on('click', function () {
        const activeTab = $(this).data('content')
        // Update Tabs
        $('#services-component .list .list-item').each(function () {
          $(this).data('content') === activeTab ? $(this).addClass('active') : $(this).removeClass('active')
        })
        // Update Contents
        $('#services-component .content-wrapper .list-content').each(function () {
          $(this).data('content') === activeTab ? $(this).addClass('active') : $(this).removeClass('active')
        })
      })
    } // END OF IF


    // const asideWaypointItem = document.getElementById('sidebar')
    // if (typeof asideWaypointItem !== 'undefined' && asideWaypointItem != null) {
    //   console.log('SIDEBAR FIXED')
    //   var waypoint = new Waypoint({
    //     element: document.getElementById('sidebar'),
    //     handler: function() {
    //       console.log('Basic waypoint triggered')
    //     }
    //   })
    // }


    const compressionElement = document.getElementById('img-compression')
    if (typeof compressionElement !== 'undefined' && compressionElement != null) {

      // SERVICE COMPONENT TAB SWITCHER
      $('#img-compression .before-after-tabs .before-after-tab').on('click', function () {
        const activeTab = $(this).data('content')
        // Update Tabs
        $('#img-compression .before-after-tabs .before-after-tab').each(function () {
          $(this).data('content') === activeTab ? $(this).addClass('active') : $(this).removeClass('active')
        })
        // Update Contents
        $('#before-after-slider .image-compares .image-compare').each(function () {
          $(this).data('content') === activeTab ? $(this).addClass('active') : $(this).removeClass('active')
        })
      })
    } // END OF IF


    // IMAGE COMPARES
    const viewers = document.querySelectorAll(".image-compare");
    viewers.forEach((element) => {
      let view = new ImageCompare(element).mount();
    });

  // END OF LOAD PAGE
})

function setupMobileMenu () {
  // mobile menu
  $('#mobile-menu .menu-item-has-children > a').after('<span class="menu-closed"></span>')
  // console.log($('#mobile-menu .menu-item-has-children > a'))
  $('#mobile-menu .menu-item-has-children > a').each(function () {
      $(this).next().next('.sub-menu').toggleClass('hide', 1000)
  })
  $('#mobile-menu .menu-item-has-children > a + span').on('click', function (event) {
      event.preventDefault()
      $(this).toggleClass('menu-open')
      $(this).next('.sub-menu').toggleClass('hide', 1000)
  })

  $('.toggle-sidebar').on('click', function (event) {
    event.preventDefault()
    // console.log('toggle navbar')
    $('aside#mobile-menu').toggleClass('open')
  })


}




function ltxChartDoughnut() {
	// var scroll = jQuery(window).scrollTop() + jQuery(window).height();
	if (jQuery(".chart-doughnut-info").length) {
		jQuery(".chart-doughnut-info:not(.inited)").each(function(i, el) {
			var canvasEl = jQuery(el).prev().get(0).getContext("2d"),
				value = jQuery(el).data('percent'),
				// scrollEl = jQuery(el).offset().top,
				colorMain = '#dfbb9c',
				colorSecond = '#f6f3ed'

			var gradient = canvasEl.createLinearGradient(0, 0, 0, 600);
      gradient.addColorStop(0, colorMain);
      gradient.addColorStop(1, '#f6f3ed');

			var data = {
				datasets: [{
				    data: [value, 100-value],
				    backgroundColor: [gradient,colorSecond]
			    }]
			};
			// if (scroll > scrollEl) {
				new Chart(canvasEl, {
					type: 'doughnut',
					data: data,
					options: {
            // aspectRatio: 1,
            // maintainAspectRatio: true,
            // aspectRatio: false,
						responsive: true,
            cutout: 30,
            plugins: {
              tooltip: { enabled: false }
            }
					}
				});
				jQuery(el).addClass('inited');
			// }
		});
	}
}

